import { createMemoryHistory, createWebHistory, createRouter } from "vue-router";
import HomeScreen from '@/views/HomeScreen.vue';
import ModeScreen from '@/views/ModeScreen.vue';
import CategoryScreen from '@/views/CategoryScreen.vue';
import QuestionScreen from '@/views/QuestionScreen.vue';
import WhoHasClientScreen from '@/views/WhoHasClientScreen.vue';

const routes = [
  {
    path: "/",
    name: "HomeScreen",
    component: HomeScreen,
  },
  {
    path: "/mode",
    name: "ModeScreen",
    component: ModeScreen,
  },
  {
    path: "/category",
    name: "CategoryScreen",
    component: CategoryScreen,
  },
  {
    path: "/question",
    name: "QuestionScreen",
    component: QuestionScreen,
  },
  {
    path: "/whohasclient",
    name: "WhoHasClient",
    component: WhoHasClientScreen,
  },
];

let history = null;
if(process.env.VUE_APP_NHIE_DEVICE == 'samsungtv'){
  history = createMemoryHistory();
} else {
  history = createWebHistory();
}

const router = createRouter({
    history: history,
    routes,
});

export default router;