<template>
  <div class="grid h-screen place-items-center">
    <div class="m-auto border-4 rounded-md text-white text-6xl p-20 w-1/2 glow-on-hover" @click="newRound()">
      New Round
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeScreen',
  data() {
    return {
      
    }
  },
  components: {
    
  },
  mounted() {

    document.addEventListener('keydown', this.handleKeyDown);

  },
  methods: {
    newRound(){
      this.$store.dispatch('startNewGame');
      this.$router.push({ name: "ModeScreen"});
    },
    handleKeyDown(e){
      switch(e.keyCode){
        case 13: // OK button
          document.removeEventListener('keydown',this.handleKeyDown);
          this.newRound();
          break;
        /*case 37: // left arrow
          this.videoJumpPosition(10, true);
          break;
        case 38: // up arrow
          this.showPlayScreenProgressControls();
          break;
        case 39: // right arrow
          this.videoJumpPosition(10, false);
          break;
        case 40: // down arrow
          this.hidePlayScreenProgressControls(false);
          break;
        case 13: // OK button
          if(this.videoMode == 'play'){
            this.pauseVideo();
          } else if(this.videoMode == 'pause'){
            if(this.jumpPreviewActive == true){
              this.videoSetPosition(this.jumpPreviewTime);
              this.jumpPreviewActive = false;
              this.jumpPreviewTime = null;
            }
            this.playVideo();
          }
          break;
        case 8:
          // stop video
          this.pauseVideo();
          document.removeEventListener('keydown',this.handleKeyDown);
          this.$router.push({ name: "TileScreen"});
          break;
        case 10009:
          // stop video
          this.pauseVideo();
          document.removeEventListener('keydown',this.handleKeyDown);
          this.$router.push({ name: "TileScreen"});
          break;*/
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.glow-on-hover {
    /*width: 220px;
    height: 50px;*/
    border: none;
    outline: none;
    color: white;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    background: transparent;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -5px;
    left:-5px;
    background-size: 400%;
    z-index: -1;
    filter: blur(10px);
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

</style>
