<template>
  <div class="h-screen grid content-center grid-cols-3 gap-20 px-60">
    <div v-for="(mode, index) in this.modes" :key="index" class="m-auto border-4 rounded-md text-white w-full text-6xl py-20 border-gray-600 " :class="{ 'glow-on-hover': getModeSelected(mode) }" @click="selectMode(mode.mode)">
      <span>{{ mode.text }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModeScreen',
  data() {
    return {
      selectedMode: 'classic',
      modes: [
        {
          mode: 'classic',
          text: 'Classic'
        },
        {
          mode: 'who_has',
          text: 'Who Has'
        },
        {
          mode: 'single',
          text: 'Singleplayer'
        },
        {
          mode: 'lobby',
          text: 'Lobby'
        },
      ],
    }
  },
  components: {
    
  },
  mounted() {

    this.$store.dispatch('getCategories').then(response => {

    console.log(response);

    });

    // change background color
    /*document.getElementById('app').style.background = '#000000';

    // set video resource and listeners
    let that = this;
    that.videoPath = process.env.VUE_APP_MEDIA_SERVER + this.$route.params.videoPath;
    // TODO check if file exist on server
    that.subsPath = that.videoPath.split('/').slice(0, -1).join('/') + '/subs.vtt';
    console.log(that.videoPath);
    console.log(that.subsPath);
    document.addEventListener('keydown', this.handleKeyDown);*/

    document.addEventListener('keydown', this.handleKeyDown);

  },
  methods: {
    getModeSelected(mode){
      if(mode.mode == this.selectedMode){
        return true;
      } else {
        return false;
      }
    },
    selectMode(mode){
      document.removeEventListener('keydown',this.handleKeyDown);
      this.$store.dispatch('setCurrentGameMode', { mode: mode });
      this.$router.push({ name: "CategoryScreen"});
    },
    moveSelectedCursor(steps){
      let currentIndex = null;
      for(let i = 0; i < this.modes.length; i++){
        if(this.modes[i].mode == this.selectedMode){
          currentIndex = i;
        }
      }
      
      // prevent too much steps
      if((currentIndex + steps) < 0){
        this.selectedMode = this.modes[0].mode;
      } else if((currentIndex + steps) >= this.modes.length){
        this.selectedMode = this.modes[this.modes.length-1].mode;
      } else {
        this.selectedMode = this.modes[currentIndex + steps].mode;
      }
    },
    handleKeyDown(e){
      switch(e.keyCode){
        case 37: // left arrow
          this.moveSelectedCursor(-1);
          break;
        case 38: // up arrow
          this.moveSelectedCursor(-3);
          break;
        case 39: // right arrow
          this.moveSelectedCursor(+1);
          break;
        case 40: // down arrow
          this.moveSelectedCursor(+3);
          break;
        case 13: // OK button
          this.selectMode(this.selectedMode);
          break;
        case 27: // Escape Button
          document.removeEventListener('keydown',this.handleKeyDown);
          this.$router.push({ name: "HomeScreen"});
          break;
        /*case 8:
          // stop video
          this.pauseVideo();
          document.removeEventListener('keydown',this.handleKeyDown);
          this.$router.push({ name: "TileScreen"});
          break;
        case 10009:
          // stop video
          this.pauseVideo();
          document.removeEventListener('keydown',this.handleKeyDown);
          this.$router.push({ name: "TileScreen"});
          break;*/
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.glow-on-hover {
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;

    color: white;
    background: transparent;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -5px;
    left:-5px;
    background-size: 400%;
    z-index: -1;
    filter: blur(10px);
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

</style>
