<template>
  <div class="h-screen grid grid-cols-3 gap-20 px-80 overflow-scroll py-10">
    <div v-for="(category) in this.categories" :key="category.id" class="m-auto border-4 rounded-md text-white w-full text-6xl py-20 border-gray-600" :id="'category-' + category.id" :class="{ 'glow-persistent': getCategorySelected(category.id), 'glow-on-hover': getCategoryCursorSelected(category) }" @click="handleCategoryClick(category.id)">
      {{ category.text }}
    </div>
  </div>
  <div class="absolute right-0 bottom-0 p-10 m-10 text-white border-4 rounded-full text-6xl border-gray-600" :class="{ 'border-white': getSelectedStartButton() }" @click="handleStartClick()">Start</div>
</template>

<script>

export default {
  name: 'CategoryScreen',
  data() {
    return {
      lastSelectedIndex: 0,
      selectedStartButton: false,
      selectedCursorCategory: null,
      categories: [],
      selectedCategories: []
    }
  },
  components: {
    
  },
  mounted() {

    let that = this;
    this.$store.dispatch('getCategories').then(response => {

      for(let i = 0; i < response.length; i++){
        that.categories.push({
          id: response[i].id,
          text: response[i].Translations[0].text
        });
      }

      that.selectedCursorCategory = that.categories[0];

    });

    // setCurrentGameMode
    this.$store.dispatch('getCurrentGame').then(response => {

      if(response.mode == 'who_has'){
        
        this.$store.dispatch('whoHasCreateSession');
      }

    });

    // change background color
    /*document.getElementById('app').style.background = '#000000';

    // set video resource and listeners
    let that = this;
    that.videoPath = process.env.VUE_APP_MEDIA_SERVER + this.$route.params.videoPath;
    // TODO check if file exist on server
    that.subsPath = that.videoPath.split('/').slice(0, -1).join('/') + '/subs.vtt';
    console.log(that.videoPath);
    console.log(that.subsPath);
    document.addEventListener('keydown', this.handleKeyDown);*/

    document.addEventListener('keydown', this.handleKeyDown);

  },
  methods: {
    getSelectedStartButton(){
      return this.selectedStartButton;
    },
    getCategoryCursorSelected(category){
      if(category.id == this.selectedCursorCategory.id && this.selectedStartButton == false){
        return true;
      } else {
        return false;
      }
    },
    getCategorySelected(categoryId){
      let categorySelected = false;
      for(let i = 0; i < this.selectedCategories.length; i++){
        if(this.selectedCategories[i] == categoryId){
          categorySelected = true;
        }
      }
      return categorySelected;
    },
    handleCategoryClick(categoryId){
      let categoriesAlwaysAdded = false;
      for(let i = 0; i < this.selectedCategories.length; i++){
        if(this.selectedCategories[i] == categoryId){
          categoriesAlwaysAdded = i;
        }
      }
      if(categoriesAlwaysAdded !== false){
        this.selectedCategories.splice(categoriesAlwaysAdded, 1);
      } else {
        this.selectedCategories.push(categoryId);
      }
    },
    handleStartClick(){
      if(this.selectedCategories.length > 0){
        document.removeEventListener('keydown',this.handleKeyDown);
        this.$store.dispatch('setCurrentGameCategories', { categoryIds: this.selectedCategories });
        this.$router.push({ name: "QuestionScreen"});
      }
    },
    moveSelectedCursor(steps){
      let currentIndex = null;
      for(let i = 0; i < this.categories.length; i++){
        if(this.categories[i].id == this.selectedCursorCategory.id){
          currentIndex = i;
        }
      }

      this.lastSelectedIndex = currentIndex;

      // check step to start button
      if(((currentIndex + 1) % 3 == 0 && steps == 1) || (currentIndex + 1) == this.categories.length && steps == 1){
        this.selectedStartButton = true;
      } else if(this.selectedStartButton == true && steps == -1){
        this.selectedStartButton = false;
        this.selectedCursorCategory = this.categories[this.lastSelectedIndex];
      } else {
        this.selectedStartButton = false;

        // prevent too much steps
        if((currentIndex + steps) < 0){
          this.selectedCursorCategory = this.categories[0];
        } else if((currentIndex + steps) >= this.categories.length){
          this.selectedCursorCategory = this.categories[this.categories.length-1];
        } else {
          this.selectedCursorCategory = this.categories[currentIndex + steps];
        }

      }

      document.getElementById('category-' + this.selectedCursorCategory.id).scrollIntoView();

    },
    handleKeyDown(e){
      switch(e.keyCode){
        case 37: // left arrow
          this.moveSelectedCursor(-1);
          break;
        case 38: // up arrow
          this.moveSelectedCursor(-3);
          break;
        case 39: // right arrow
          this.moveSelectedCursor(+1);
          break;
        case 40: // down arrow
          this.moveSelectedCursor(+3);
          break;
        case 13: // OK button
          if(this.selectedStartButton == true){
            this.handleStartClick();
          } else {
            this.handleCategoryClick(this.selectedCursorCategory.id);
          }
          break;
        case 27: // Escape Button
          document.removeEventListener('keydown',this.handleKeyDown);
          this.$router.push({ name: "HomeScreen"});
          break;
        /*case 8:
          // stop video
          this.pauseVideo();
          document.removeEventListener('keydown',this.handleKeyDown);
          this.$router.push({ name: "TileScreen"});
          break;
        case 10009:
          // stop video
          this.pauseVideo();
          document.removeEventListener('keydown',this.handleKeyDown);
          this.$router.push({ name: "TileScreen"});
          break;*/
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.glow-on-hover {
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;

    color: white;
    background: transparent;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -5px;
    left:-5px;
    background-size: 400%;
    z-index: -1;
    filter: blur(10px);
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.glow-persistent {
  color: #db3eb1;
  border: 6px solid #db3eb1;
  filter: drop-shadow(0 0 15px #db3eb1) drop-shadow(0 0 50px #db3eb1) contrast(2) brightness(2);
  transition: .5s;
}

</style>
