import { createStore } from 'vuex'
import axios from 'axios';
import { uuid } from 'vue-uuid';

//const managerpanel_accountingmanagement_backend_url = process.env.VUE_APP_MANAGERPANEL_ACCOUNTINGMANAGEMENT_BACKEND_URL;

export const store = createStore({
  state () {
    return {
      categories: [],
      currentGame: {
        mode: null,
        selectedCategories: [],
        socketsession: null,
        userId: null,
        sessionId: null,
        userType: null,
        users: [],
        currentAnswerData: [],
        triggerNextQuestionCount: 0,
        finish: false
      }
    }
  },
  getters: {
    getCurrentGame(state){
      return state.currentGame;
    },
    getCurrentGameUsers(state){
      return state.currentGame.users;
    },
    getCurrentGameMode(state){
      return state.currentGame.mode;
    },
    getCurrentGameCurrentAnswerData(state){
      return state.currentGame.currentAnswerData;
    },
    getCurrentGameUserId(state){
      return state.currentGame.userId;
    },
  },
  mutations: {
    SET_CURRENT_GAME(state, game){
      state.currentGame = game;
    },
  },
  actions: {
    async startNewGame({ commit }){
      commit('SET_CURRENT_GAME', { mode: null, selectedCategories: [], socketsession: null, userId: null, sessionId: null, users: [], currentAnswerData: [] });
    },
    async setCurrentGameMode({ getters }, { mode }){
      let currentGame = getters.getCurrentGame;
      currentGame.mode = mode;
    },
    async setCurrentGameCategories({ getters }, { categoryIds }){
      let currentGame = getters.getCurrentGame;
      currentGame.selectedCategories = categoryIds;
    },
    async getCurrentGame({ getters }){
      return getters.getCurrentGame;
    },
    async getCategories(){

      let categories = [];

      const url = process.env.VUE_APP_NHIE_BACKEND + '/categories';
        await axios.get(url).then(response => {
          categories = response.data;
      });

      return categories;


    },
    async getQuestionsByCategories({ getters }, { categoryIds }){
console.log(getters);
      let questions = [];

      const url = process.env.VUE_APP_NHIE_BACKEND + '/questions?translations=1&filtercategories='+categoryIds.join(',');
      console.log(url);
        await axios.get(url).then(response => {
          questions = response.data;
      });

      return questions;


    },
    async whoHasCreateSession({ getters }){
      const currentGame = getters.getCurrentGame;
      
      const socketsession = new WebSocket(process.env.VUE_APP_NHIE_BACKEND_SOCKET + "/who-has-socket");
      socketsession.onmessage = function(event){
        const data = JSON.parse(event.data);
        if(data.message == 'user_joined'){
          let founded = false;
          for(let i = 0; i < currentGame.users.length; i++){
            if(currentGame.users[i].id == data.data.id){
              founded = true;
            }
          }
          if(founded == false){
            currentGame.users.push(data.data);
          }
        } else if(data.message == 'send_answer_data'){
          currentGame.currentAnswerData.push(data.data);
        }
      }

      socketsession.onopen = function(){
        console.log("successfully connected to the echo websocket server");
        currentGame.userId = uuid.v4();
        socketsession.send(JSON.stringify({
          "type":"host",
          "command":"new_session",
          "id": currentGame.userId,
          "roomId": 'dc138756-a7ca-4e23-b1d1-de6e4a47b286'
        }));
        currentGame.sessionId = 'dc138756-a7ca-4e23-b1d1-de6e4a47b286';
        currentGame.userType = 'host';
      }
      
      currentGame.socketsession = socketsession;

    },
    async whoHasJoinSession({ getters }, { sessionId, username }){
      const currentGame = getters.getCurrentGame;
      currentGame.userId = uuid.v4();
      currentGame.sessionId = sessionId;

      const socketUrl = process.env.VUE_APP_NHIE_BACKEND_SOCKET + "/who-has-socket";
      //const socketUrl = 'ws://10.200.16.126:8080/who-has-socket';
      const socketsession = new WebSocket(socketUrl);
      socketsession.onmessage = function(event){
        const data = JSON.parse(event.data);
        console.log(data);
        if(data.message == 'get_current_users'){
          currentGame.users = data.data;
        } else if(data.message == 'user_joined'){
          let founded = false;
          for(let i = 0; i < currentGame.users.length; i++){
            if(currentGame.users[i].id == data.data.id){
              founded = true;
            }
          }
          if(founded == false){
            currentGame.users.push(data.data);
          }
        } else if(data.message == 'next_question'){
          currentGame.triggerNextQuestionCount++;
        } else if(data.message == 'finish_round'){
          currentGame.finish = true;
        }
      }

      socketsession.onopen = function(){
        console.log("successfully connected to the echo websocket server");
        socketsession.send(JSON.stringify({
          "type":"client",
          "command":"join",
          "roomId": sessionId,
          "data":{
            "username": username
          },
          "id": currentGame.userId
        }));
      }
      
      currentGame.socketsession = socketsession;
    },
    async whoHasGetSessionUsers({ getters }){
      const currentGame = getters.getCurrentGame;
      currentGame.socketsession.send(JSON.stringify({
        "type":"client",
        "command":"get_current_users",
        "roomId": currentGame.sessionId,
        "id": currentGame.userId
      }));
    },
    async whoHasSendAnswer({ getters }, { data }){
      const currentGame = getters.getCurrentGame;
      currentGame.socketsession.send(JSON.stringify({
        "type":"client",
        "command":"send_answer_data",
        "roomId": currentGame.sessionId,
        "id": currentGame.userId,
        "data":{
          "answer": data
        },
      }));
    },
    async whoHasSendNextQuestion({ getters }){
      const currentGame = getters.getCurrentGame;
      currentGame.currentAnswerData = [];
      currentGame.socketsession.send(JSON.stringify({
        "type":"host",
        "command":"next_question",
        "roomId": currentGame.sessionId,
        "id": currentGame.userId,
      }));
    },
    async whoHasSendFinishRound({ getters }){
      const currentGame = getters.getCurrentGame;
      currentGame.currentAnswerData = [];
      currentGame.socketsession.send(JSON.stringify({
        "type":"host",
        "command":"finish_round",
        "roomId": currentGame.sessionId,
        "id": currentGame.userId,
      }));
    },
  }
})