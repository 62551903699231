<template>
  <div class="grid h-screen place-items-center">
    <div class="text-white text-4xl p-10 glow-on-hover" v-if="currentQuestion != null">{{ currentQuestion.Translations[0].text }}</div>
    <div class="absolute right-0 top-0 p-10 text-white">{{ this.questionPlayed + ' / ' + this.questionCount }}</div>
    <div v-if="this.currentGame == 'who_has' && this.getCompleteUserAnswers != false" class="w-full px-10">
      <table class="w-full text-white text-2xl answer-table">
        <thead>
          <th></th>
          <th v-for="(user) in this.getCalculatedUserAnswers" :key="user.userId">{{ user.username }}</th>
        </thead>
        <tbody>
          <tr v-for="(user) in this.getCalculatedUserAnswers" :key="user.userId">
            <td>{{ user.username }}</td>
            <td v-for="(result, index) in user.results" :key="index">
              <div v-if="result == true" class="w-10 h-10 glow-square-green"></div>
              <div v-else class="w-10 h-10 glow-square-red"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="this.currentGame == 'who_has'" class="absolute bottom-0 w-full grid grid-flow-col gap-10 px-10">
      <div v-for="(user) in this.sessionUsers" :key="user.id" class="m-auto border-4 rounded-md text-white w-full text-4xl py-10 my-5 border-gray-600" :class="{ 'glow-persistent': getUserAnswered(user.id), 'glow-on-hover': !getUserAnswered(user.id) }">
        {{ user.username }}
      </div>
    </div>
    <div v-if="this.currentGame == 'who_has'" class="absolute left-0 top-0 p-10">
      <qrcode-vue :value="this.currentGameSessionUrl" :size="size" level="H" />
    </div>
  </div>
</template>

<script>

import QrcodeVue from 'qrcode.vue';

export default {
  name: 'QuestionScreen',
  data() {
    return {
      currentQuestion: null,
      questions: [],
      questionCount: 0,
      questionPlayed: 0,
      currentGameSessionUrl: null
    }
  },
  components: {
    QrcodeVue
  },
  computed:{ 
    currentGame(){
      return this.$store.getters.getCurrentGameMode;
    },
    sessionUsers(){
      return this.$store.getters.getCurrentGameUsers;
    },
    getCompleteUserAnswers(){
      const currentGameUsers = this.sessionUsers;
      const currentAnswerData = this.$store.getters.getCurrentGameCurrentAnswerData;
      if(currentGameUsers.length !== 0 && currentAnswerData.length !== 0 && currentGameUsers.length == currentAnswerData.length){
        return true;
      } else {
        return false;
      }
    },
    getCalculatedUserAnswers(){
      const data = [];
      const currentGameUsers = this.sessionUsers;
      const currentAnswerData = this.$store.getters.getCurrentGameCurrentAnswerData;
      for (let user of currentGameUsers) {

        const userdata = { userId: user.id, username: user.username, results: [] };

        // find user answer
        let answer = null;
        for (let useranswer of currentAnswerData) {
          if(useranswer.userId == user.id){
            answer = useranswer.data.answer;
            break;
          }
        }

        for (let user2 of currentGameUsers) {
          let predictedAnswer = null;
          if(user2.id !== user.id){
            if(answer.selectedUserIds.includes(user2.id)){
              predictedAnswer = true;
            } else {
              predictedAnswer = false;
            }
            userdata.results.push(this.getUserAnswerCorrect(predictedAnswer, user2.id));
          } else {
            userdata.results.push(answer.ownAnswer);
          }
        }
        data.push(userdata);
      }
      
      return data;

    }
  },
  mounted() {

    let that = this;

    this.$store.dispatch('getCurrentGame').then(response => {
      
      this.$store.dispatch('getQuestionsByCategories', { categoryIds: response.selectedCategories }).then(response => {

        that.questions = response;  
        that.questionCount = that.questions.length;
        that.showNextQuestion();

      });

      that.currentGameSessionUrl = process.env.VUE_APP_CURRENT_URL + '/whohasclient?sessionId=' + response.sessionId;

    });
    


    // change background color
    /*document.getElementById('app').style.background = '#000000';

    // set video resource and listeners
    let that = this;
    that.videoPath = process.env.VUE_APP_MEDIA_SERVER + this.$route.params.videoPath;
    // TODO check if file exist on server
    that.subsPath = that.videoPath.split('/').slice(0, -1).join('/') + '/subs.vtt';
    console.log(that.videoPath);
    console.log(that.subsPath);*/
    document.addEventListener('keydown', this.handleKeyDown);

  },
  methods: {
    // return if the predicted answer was correct or not
    getUserAnswerCorrect(predictedAnswer, userId){
      const currentAnswerData = this.$store.getters.getCurrentGameCurrentAnswerData;
      for (let useranswer of currentAnswerData) {
        if(useranswer.userId == userId){
          if(useranswer.data.answer.ownAnswer == predictedAnswer){
            return true;
          } else {
            return false;
          }
        }
      }
    },
    getUserAnswered(userId){
      const currentAnswerData = this.$store.getters.getCurrentGameCurrentAnswerData;
      let answered = false;
      for (let answer of currentAnswerData) {
        if(answer.userId == userId){
          answered = true;
          break;
        }
      }
      return answered;
    },
    showNextQuestion(){

      if(this.questions.length <= 0){
        this.$store.dispatch('whoHasSendFinishRound');
        this.$router.push({ name: "HomeScreen"});
      }

      // send next question by who has game mode
      if(this.currentGame == 'who_has'){
        this.$store.dispatch('whoHasSendNextQuestion').then(() => {});
      }

      const randomIndex = Math.floor(Math.random()*this.questions.length);
      this.currentQuestion = this.questions[randomIndex];
      this.questions.splice(randomIndex, 1);
      this.questionPlayed++;

    },
    handleKeyDown(e){
      switch(e.keyCode){
        case 13: // OK Button or Enter Button
          if(this.currentGame == 'who_has' && this.getCompleteUserAnswers == false){
            return;
          }
          this.showNextQuestion();
          break;
        case 27: // Escape Button
          document.removeEventListener('keydown',this.handleKeyDown);
          this.$router.push({ name: "HomeScreen"});
          break;
        /*case 37: // left arrow
          this.videoJumpPosition(10, true);
          break;
        case 38: // up arrow
          this.showPlayScreenProgressControls();
          break;
        case 39: // right arrow
          this.videoJumpPosition(10, false);
          break;
        case 40: // down arrow
          this.hidePlayScreenProgressControls(false);
          break;
        case 13: // OK button
          if(this.videoMode == 'play'){
            this.pauseVideo();
          } else if(this.videoMode == 'pause'){
            if(this.jumpPreviewActive == true){
              this.videoSetPosition(this.jumpPreviewTime);
              this.jumpPreviewActive = false;
              this.jumpPreviewTime = null;
            }
            this.playVideo();
          }
          break;
        case 8:
          // stop video
          this.pauseVideo();
          document.removeEventListener('keydown',this.handleKeyDown);
          this.$router.push({ name: "TileScreen"});
          break;
        case 10009:
          // stop video
          this.pauseVideo();
          document.removeEventListener('keydown',this.handleKeyDown);
          this.$router.push({ name: "TileScreen"});
          break;*/
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.answer-table td, th {
  border: 1px solid red;
  text-align: center;
}
.answer-table td div {
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.glow-square-green {
  background: #23c542;
  box-shadow: 0px 0px 5px #23c542, 0px 0px 25px #23c542, 0px 0px 50px #23c542;
}

.glow-square-red {
  background: #e91818;
  box-shadow: 0px 0px 5px #e91818, 0px 0px 25px #e91818, 0px 0px 50px #e91818;
}

.glow-on-hover {
    border: none;
    outline: none;
    color: white;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    background: transparent;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -5px;
    left:-5px;
    background-size: 400%;
    z-index: -1;
    filter: blur(10px);
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.glow-persistent {
  color: #db3eb1;
  border: 6px solid #db3eb1;
  filter: drop-shadow(0 0 15px #db3eb1) drop-shadow(0 0 50px #db3eb1) contrast(2) brightness(2);
  transition: .5s;
}

</style>
