<template>
  <div v-if="this.finishScreen == true">
    <div class="h-screen py-10 px-10 flex items-center justify-center">
      <div class="border-4 rounded-md text-white w-full text-4xl py-10 my-5 border-gray-600 glow-on-hover">
        Runde vorbei
      </div>
    </div>
  </div>
  <div v-else-if="this.waitingScreenEnabled == true || this.waitingStartScreenEnabled == true">
    <div class="h-screen py-10 px-10 flex items-center justify-center">
      <div class="border-4 rounded-md text-white w-full text-4xl py-10 my-5 border-gray-600 glow-on-hover">
        Warten
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="this.sessionUsers.length == 0">
      <div class="h-screen grid">
        <input type="text" class="m-auto border-4 rounded-md text-white w-10/12 h-20 text-4xl border-gray-600 glow-persistent" style="background: #121212" v-model="this.username" />
      </div>
      <div class="absolute right-0 bottom-0 p-10 m-10 text-white border-4 rounded-full text-6xl border-gray-600" @click="handleStartClick()">Start</div>
    </div>
    <div class="h-screen grid py-10 px-10" v-if="this.sessionUsers.length > 0 && this.ownAnswer == null">
      <div class="m-auto border-4 rounded-md text-white w-full text-4xl py-10 my-5 border-gray-600 glow-on-hover" @click="handleOwnAnswerClick(true)">
        JA
      </div>
      <div class="m-auto border-4 rounded-md text-white w-full text-4xl py-10 my-5 border-gray-600 glow-on-hover" @click="handleOwnAnswerClick(false)">
        NEIN
      </div>
    </div>
    <div class="h-screen grid py-10 px-10" v-if="this.sessionUsers.length > 0 && this.ownAnswer != null">
      <div v-for="(user) in this.sessionUsers" :key="user.id" class="m-auto border-4 rounded-md text-white w-full text-4xl py-10 my-5 border-gray-600" :class="{ 'glow-persistent': getUserSelected(user.id), 'glow-on-hover': !getUserSelected(user.id) }" :id="'user-' + user.id" @click="handleUserClick(user)">
        {{ user.username }}
      </div>
      <div class="p-10 my-10 text-white border-4 rounded-full text-6xl border-gray-600" @click="handleSendingClick()">Senden</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WhoHasClientScreen',
  data() {
    return {
      waitingStartScreenEnabled: false,
      waitingScreenEnabled: false,
      username: 'bla',
      socketSessionReady: false,
      selectedUsers: [],
      ownAnswer: null,
      finishScreen: false
    }
  },
  computed:{ 
    sessionUsers(){

      const currentGameUsers = this.$store.getters.getCurrentGameUsers;
      const currentUserId = this.$store.getters.getCurrentGameUserId;
      const users = [];
      for (let user of currentGameUsers) {
        if (user.id !== currentUserId) {
          users.push(user);
        }
      }
      return users;
    },
  },
  components: {
    
  },
  watch: {
    // whenever question changes, this function will run
    sessionUsers(newValue) {
      if(newValue.length > 0){
        this.waitingStartScreenEnabled = false;
      }
    },
    '$store.state.currentGame.triggerNextQuestionCount': {
      deep: true,
      handler(newVal) {
        if(newVal > 0){
          this.reset();
        }
      }
    },
    '$store.state.currentGame.finish': {
      deep: true,
      handler(newVal) {
        if(newVal == true){
          this.finishScreen = true;
        }
      }
    }
  },
  mounted() {



  },
  methods: {
    handleStartClick(){
      this.waitingStartScreenEnabled = true;
      this.$store.dispatch('whoHasJoinSession', { sessionId: this.$route.query.sessionId, username: this.username }).then(() => {

        const that = this;
        const socketInterval = setInterval(function(){

          that.$store.dispatch('getCurrentGame').then(response => {

            if(response.socketsession.readyState == 1){
              
              clearInterval(socketInterval);
              that.$store.dispatch('whoHasGetSessionUsers').then(() => {

              });

            }
            

          });

        }, 100);

      });
    },
    handleUserClick(user){
      let found = false;
      for(let i = 0; i < this.selectedUsers.length; i++){
        if(this.selectedUsers[i].id == user.id){
          found = true;
        }
      }
      if(found == false){
        this.selectedUsers.push(user);
      } else {
        for(let i = 0; i < this.selectedUsers.length; i++){
          if(this.selectedUsers[i].id == user.id){
            this.selectedUsers.splice(i, 1);
          }
        }
      }
    },
    getUserSelected(id){
      let selected = false;
      for(let i = 0; i < this.selectedUsers.length; i++){
        if(this.selectedUsers[i].id == id){
          selected = true;
        }
      }
      return selected;
    },
    handleSendingClick(){
      
      this.waitingScreenEnabled = true;

      const selectedUserIds = [];
      for(let i = 0; i < this.selectedUsers.length; i++){
        selectedUserIds.push(this.selectedUsers[i].id);
      }

      this.$store.dispatch('whoHasSendAnswer', { data: { ownAnswer: this.ownAnswer, selectedUserIds: selectedUserIds } }).then(() => {

          

      });

      // enable waiting screen

    },
    handleOwnAnswerClick(answer){
      this.ownAnswer = answer;
    },
    reset(){
      this.ownAnswer = null;
      this.selectedUsers = [];
      this.waitingScreenEnabled = false;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.glow-on-hover {
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;

    color: white;
    background: transparent;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -5px;
    left:-5px;
    background-size: 400%;
    z-index: -1;
    filter: blur(10px);
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.glow-persistent {
  color: #db3eb1;
  border: 6px solid #db3eb1;
  filter: drop-shadow(0 0 15px #db3eb1) drop-shadow(0 0 50px #db3eb1) contrast(2) brightness(2);
  transition: .5s;
}

</style>
